import React from 'react'
import { connect } from 'react-redux'
import { translate, t, getLanguage } from 'react-multi-lang'
import base64url from "base64url"

import constants from '../../../constants/constants'

import {
  changeContactEmail,
  changeFormId,
  submitForm,
  uploadFile,
} from '../../../actions/index'

import {
  validateEmail,
  processString,
  timestampFile,
}  from '../../../helpers'

import PageManager from '../../elements/pageManager'

/**
 * Formulaire de contact
 */
class FormContact extends React.Component {
  state = {
    displayError: false,
  }

  /**
   * Fonction appelée lors d'une modification de l'input email.
   * Met à jour la valeur de l'email, ainsi que la vérification que celui-ci est valide.
   *
   * @param {Object} event Événement déclenché ; la nouvelle valeur entrée est
   *   contenue dans <code>event.target.value</code>
   */
  onChangeEmail = (event) => {
    const { changeContactEmail } = this.props

    const value = event.target.value
    const valid = validateEmail(value)

    changeContactEmail(value, valid)
  }

  /**
   * Fonction appelée au clic sur le bouton d'envoi.
   * Si l'email est valide, on envoie la demande de soumission.
   * Dans le cas contraire, on affiche un message d'erreur.
   */
  onSubmit = () => {
    const {
      infos,
      title,
      gallery,
      payment,
      form,
      contactEmail,
      changeFormId,
      uploadFile,
      submitForm,
    } = this.props

    const fields = []
    for (let i = 0; i < form.length; i++) {
      if (form[i].included) {
        let label = form[i].label
        if (!label && form[i].original) {
          label = t('form.custom.fields.' + form[i].name)
        }
        fields.push({
          label,
          type: form[i].type,
          required: form[i].required
        })
      }
    }

    if (!contactEmail.valid) {
      this.setState({ displayError: true })
    } else {
      const timestamp = new Date().getTime()
      const formId = base64url(timestamp.toString())

      changeFormId(formId)

      let logoName = ''
      if (infos.logo) {
        let logoFile = infos.logo
        const logoType = logoFile.type
        const blob = logoFile.slice(0, logoFile.size, logoType)
        logoName = timestampFile(logoFile.name)
        logoFile = new File([blob], logoName, {type: logoType})
        uploadFile(logoFile)
      }

      const dataGallery = []
      if (gallery.length > 0) {
        for (let i = 0; i < gallery.length; i++) {
          if (gallery[i].type === 'image') {
            let fileObject = gallery[i].file
            const fileType = fileObject.type
            const fileBlob = fileObject.slice(0, fileObject.size, fileType)
            const fileName = timestampFile(fileObject.name)
            fileObject = new File([fileBlob], fileName, {type: fileType})
            uploadFile(fileObject)
            dataGallery.push({
              type: 'image',
              value: constants.url.images + fileName,
            })
          } else {
            dataGallery.push({
              type: 'video',
              value: gallery[i].url,
            })
          }
        }
      }

      submitForm({
        id: formId,
        email: processString(contactEmail.value),
        language: getLanguage(),
        amount: payment.amount,
        currency: payment.currency,
        data: {
          infos: {
            logo: constants.url.images + logoName,
            title: processString(title),
            text: processString(infos.text),
            gallery: dataGallery,
          },
          payment,
          fields,
        },
      })
    }
  }

  /**
   * Render
   */
  render() {
    const {
      contactEmail,
    } = this.props

    const {
      displayError,
    } = this.state

    return (
      <section className="appFormSection">
        <h2 dangerouslySetInnerHTML={{ __html: t('form.contact.title')}} />
        <div className="appFormContainer">
          <div className="appFormInputContainer appFormInputContainerOld">
            <label htmlFor="appFormContactEmail">{t('form.contact.email')}</label>
            <input
              id="appFormContactEmail"
              type="text"
              value={contactEmail ? contactEmail.value : ''}
              placeholder={t('form.contact.emailPlaceholder')}
              onChange={this.onChangeEmail}
            />
            { displayError && !contactEmail.valid
              && (
                <span className="appFormError">{t('form.error.email')}</span>
              )
            }
          </div>
          <div className="appFormInputContainer appFormInputContainerOld">
            <button
              onClick={this.onSubmit}
            >{t('form.contact.button')}</button>
          </div>
        </div>
        <PageManager noNext />
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    infos: state.formReducer.infos,
    title: state.formReducer.title,
    gallery: state.formReducer.gallery,
    payment: state.formReducer.payment,
    form: state.formReducer.form,
    contactEmail: state.formReducer.contactEmail,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeContactEmail: (value, valid) => dispatch(changeContactEmail(value, valid)),
    changeFormId: (formId) => dispatch(changeFormId(formId)),
    uploadFile: file => dispatch(uploadFile(file)),
    submitForm: (data) => dispatch(submitForm(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(FormContact))
