import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

import Switch from '@material-ui/core/Switch'

import {
  changeForm,
} from '../../../actions/index'

import addIcon from '../../../../assets/images/add.svg'

import PageManager from '../../elements/pageManager'

/**
 * Formulaire de personnalisation
 */
class FormCustom extends React.Component {
  onChangeLabel = (index, value) => {
    const {
      form,
      changeForm,
    } = this.props

    const newForm = [...form]
    newForm[index].label = value
    changeForm(newForm)
  }

  onChangeIncluded = (index) => {
    const {
      form,
      changeForm,
    } = this.props

    const newForm = [...form]
    newForm[index].included = !form[index].included
    if (!newForm[index].included) {
      newForm[index].required = false
    }
    changeForm(newForm)
  }

  onChangeRequired = (index) => {
    const {
      form,
      changeForm,
    } = this.props

    const newForm = [...form]
    newForm[index].required = !form[index].required
    if (newForm[index].required) {
      newForm[index].included = true
    }
    changeForm(newForm)
  }

  addField = () => {
    const {
      form,
      changeForm,
    } = this.props

    const newForm = [...form]
    newForm.push({
      name: '',
      label: 'Nouveau champ',
      type: 'text',
      included: true,
      required: false,
      original: false,
    })
    changeForm(newForm)
  }

  render() {
    const {
      form,
    } = this.props

    return (
      <section className="appFormSection">
        <h2 dangerouslySetInnerHTML={{ __html: t('form.custom.title')}} />
        <div className="appFormContainer">
          <table id="appFormCustom">
            <thead>
              <tr>
                <th scope="col">{t('form.custom.header.field')}</th>
                <th scope="col">{t('form.custom.header.included')}</th>
                <th scope="col">{t('form.custom.header.required')}</th>
              </tr>
            </thead>
            <tbody>
              {form && form.map((item, index) => (
                <tr key={item.name}>
                  <th scope="row">
                    <input
                      type="text"
                      value={item.original && item.label === '' ? t('form.custom.fields.' + item.name) : item.label}
                      onChange={(event) => this.onChangeLabel(index, event.target.value)}
                    />
                  </th>
                  <td>
                    <Switch
                      checked={item.included}
                      onChange={() => this.onChangeIncluded(index)}
                      color="primary"
                    />
                  </td>
                  <td>
                    <Switch
                      checked={item.required}
                      onChange={() => this.onChangeRequired(index)}
                      color="primary"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            id="appFormAddField"
            role="button"
            onClick={this.addField}
          >
            <img src={addIcon} alt="" />
            {t('form.custom.add')}
          </div>
        </div>
        <PageManager />
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    form: state.formReducer.form,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeForm: (form) => dispatch(changeForm(form)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(FormCustom))
