/* eslint-disable no-useless-escape */

/**
 * Fonctions génériques
 */

/**
 * Vérifie la validité d'un email
 * @param {string} email Email à valider
 * @returns {bool} true si l'email est valide, false dans le cas contraire
 */
const validateEmail = (email) => {
  if (!email || email === '') {
    return false
  }

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

/**
 * Remplace les caractères spéciaux (\n, \t) d'une chaîne de caractères
 *
 * @param {*} str Chaîne à convertire
 * @returns Chaîne convertie
 */
const processString = (str) => {
  if (str) {
    return str.replace(/(\r\n|\n|\r)/gm, '\\n').replace(/\t/gm, '\\t')
  }

  return ''
}

/**
 * Ajoute un timestamp à un nom de fichier, afin d'éviter un écrasement
 * lors de l'upload.
 *
 * @param {*} fileName Nom du fichier à convertir
 * @returns Nom converti
 */
const timestampFile = (fileName) => {
  const timestamp = new Date().getTime()
  const index = fileName.lastIndexOf('.')
  if (index >= 0) {
    return fileName.substr(0, index) + '.' + timestamp + fileName.substr(index)
  }
  return fileName + '.' + timestamp
}

/**
 * Convertit un nombre en prix, formaté suivant la langue :
 *   "50,00 €" en français
 *   "€ 50.00" en anglais
 *
 * @param {number} price Prix à mettre en forme
 * @param {string} [currency='€'] Devise
 * @param {string} [language='en'] Langue
 * @returns {string} Prix mis en forme
 */
const formatPrice = (price, currency = '€', language = 'en') => {
  // Affichage du nombre avec deux décimales
  const priceString = price.toFixed(2)

  // Français : on remplace le point décimal par une virgule
  // et on place la devise à la fin
  if (language === 'fr') {
    return priceString.replace('.', ',') + ' ' + currency
  }

  // Anglais : devise au début
  return currency + ' ' + priceString
}

export {
  validateEmail,
  processString,
  timestampFile,
  formatPrice,
}
