import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

import {
  changeContactValue,
  changeFieldValue,
  submitUserForm,
  changeDisplayErrors,
} from '../../../actions/index'

import {
  validateEmail,
  processString,
}  from '../../../helpers'

import DataField from '../../elements/dataField'

/**
 * Section formulaire
 */
class DataContact extends React.Component {
  state = {
    displayErrors: false,
  }

  onChange = (field, value, valid = true) => {
    const { changeContactValue } = this.props

    changeContactValue(field, value, valid)
  }

  onSubmit = () => {
    const {
      userContact,
      userForm,
      changeContactValue,
      changeFieldValue,
      changeDisplayErrors,
      submitUserForm,
    } = this.props

    const newFields = [...userForm.data.fields]

    let errorFound = false

    // Vérification des erreurs éventuelles du formulaire de contact
    if (!userContact.name.value || !userContact.email.value || !userContact.phone.value) {
      errorFound = true
    }
    if (!validateEmail(userContact.email.value)) {
      changeContactValue('email', userContact.email.value, false)
      errorFound = true
    } else {
      changeContactValue('email', userContact.email.value, true)
    }

    // Vérification des erreurs éventuelles du formulaire prospect
    for (let i = 0; i < newFields.length; i++) {
      // Le champ est-il obligatoire et vide ?
      if (!newFields[i].value && newFields[i].required) {
        errorFound = true
      }

      // Le format du champ est-il incorrect ?
      if (newFields[i].type === 'email' && newFields[i].value) {
        if (!validateEmail(newFields[i].value)) {
          newFields[i].valid = false
          errorFound = true
        } else {
          newFields[i].valid = true
        }
      } else {
        newFields[i].valid = true
      }

      changeFieldValue(i, newFields[i].value, newFields[i].valid)
    }

    if (errorFound) {
      changeDisplayErrors(true)
    } else {
      const contact = {
          name: processString(userContact.name.value),
          email: processString(userContact.email.value),
          phone: processString(userContact.phone.value),
        }
      const data = {}
      for (let i = 0 ; i < userForm.data.fields.length; i++) {
        data[userForm.data.fields[i].label] = processString(userForm.data.fields[i].value)
      }
      submitUserForm({
        id: userForm.id,
        contact,
        data,
        wallet: sessionStorage.getItem('BTU-walletAddr'),
      })
    }
  }

  /**
   * Render
   */
  render() {
    const {
      displayErrors,
      userContact,
    } = this.props

    return (
      <section className="appDataSection">
        <h2 dangerouslySetInnerHTML={{ __html: t('user.contact.title')}} />
        <div className="appDataContainer appDataContainerLeft">
          <DataField
            type="text"
            value={userContact && userContact.name ? userContact.name.value : ''}
            label={t('user.contact.name')}
            required={true}
            invalid={userContact && userContact.name ? !userContact.name.valid : false}
            displayError={displayErrors}
            onChange={(event) => this.onChange('name', event.target.value)}
            autocomplete="name"
          />
          <DataField
            type="email"
            value={userContact && userContact.email ? userContact.email.value : ''}
            label={t('user.contact.email')}
            required={true}
            invalid={userContact && userContact.email ? !userContact.email.valid : false}
            displayError={displayErrors}
            onChange={(event) => this.onChange('email', event.target.value)}
            autocomplete="email"
          />
          <DataField
            type="tel"
            value={userContact && userContact.phone ? userContact.phone.value : ''}
            label={t('user.contact.phone')}
            required={true}
            invalid={userContact && userContact.phone ? !userContact.phone.valid : false}
            displayError={displayErrors}
            onChange={(event) => this.onChange('phone', event.target.value)}
            autocomplete="phone"
          />
          <div className="appDataInputContainer">
            <span className="appDataRequired">{t('user.form.required')}</span>
          </div>
          <div className="appDataInputContainer appDataInputContainerCenter">
            <button
              onClick={this.onSubmit}
            >
              {t('user.button')}
            </button>
          </div>
        </div>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    displayErrors: state.dataReducer.displayErrors,
    userContact: state.dataReducer.userContact,
    userForm: state.dataReducer.userForm,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeContactValue: (field, value, valid) => dispatch(changeContactValue(field, value, valid)),
    changeFieldValue: (index, value, valid) => dispatch(changeFieldValue(index, value, valid)),
    changeDisplayErrors: value => dispatch(changeDisplayErrors(value)),
    submitUserForm: form => dispatch(submitUserForm(form)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate(DataContact))
