import {
  CHANGE_PAGE,
  CHANGE_LOGO,
  CHANGE_INFOS,
  CHANGE_TITLE,
  CHANGE_PAYMENT,
  CHANGE_FORM,
  CHANGE_GALLERY,
  CHANGE_CONTACT_EMAIL,
  CHANGE_FORM_ID,
  SUBMIT_FORM_READY,
  RESET_FORM,
} from '../constants/actionTypes'

import initialState from './initialState'

export default function formReducer(state, action) {
  // Initialisation de l'état s'il n'est pas défini
  if (!state) {
    state = JSON.parse(JSON.stringify(initialState))
  }

  switch (action.type) {
    case CHANGE_PAGE:
      return Object.assign({}, state, {
        page: action.payload.page,
      })

    case CHANGE_LOGO:
      return Object.assign({}, state, {
        infos: {
          logo: action.payload.logo,
          text: state.infos.text,
        }
      })

    case CHANGE_INFOS:
      return Object.assign({}, state, {
        infos: {
          logo: action.payload.logo,
          text: action.payload.text,
        }
      })

    case CHANGE_TITLE:
      return Object.assign({}, state, {
        title: action.payload.title,
      })

    case CHANGE_PAYMENT:
      let moment = null
      if (typeof action.payload.moment === 'string') {
        moment = parseInt(action.payload.moment)
      } else
      if (typeof action.payload.moment === 'number') {
        moment = action.payload.moment
      }

      return Object.assign({}, state, {
        payment: {
          currency: action.payload.currency,
          amount: action.payload.amount,
          moment: moment,
        }
      })

    case CHANGE_FORM:
      return Object.assign({}, state, {
        form: [...action.payload],
      })

    case CHANGE_GALLERY:
      return Object.assign({}, state, {
        gallery: [...action.payload],
      })

    case CHANGE_CONTACT_EMAIL:
      return Object.assign({}, state, {
        contactEmail: {
          value: action.payload.value,
          valid: action.payload.valid,
        }
      })

    case CHANGE_FORM_ID:
      return Object.assign({}, state, {
        formId: action.payload.formId,
      })

    case SUBMIT_FORM_READY:
      return Object.assign({}, state, {
        page: 4,
      })

    case RESET_FORM:
      return Object.assign({}, state, {
        infos: {...initialState.infos},
        payment: {...initialState.payment},
        gallery: [...initialState.gallery],
        contactEmail: {...initialState.contactEmail},
        formId: initialState.formId,
        form: [...initialState.form],
        page: 0,
        displayErrors: false,
      })
    
    default:
      return state
  }
}
