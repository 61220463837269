/**
 * Actions liées à la création de formulaire
 */

import {
  CHANGE_PAGE,
  CHANGE_LOGO,
  CHANGE_INFOS,
  CHANGE_TITLE,
  CHANGE_PAYMENT,
  CHANGE_FORM,
  CHANGE_GALLERY,
  CHANGE_CONTACT_EMAIL,
  CHANGE_FORM_ID,
  SUBMIT_FORM,
  UPLOAD_FILE,
  RESET_FORM,
} from '../constants/actionTypes'

export function changePage(page) {
  return {
    type: CHANGE_PAGE,
    payload: {
      page,
    }
  }
}

export function changeLogo(logo) {
  return {
    type: CHANGE_LOGO,
    payload: {
      logo,
    }
  }
}

export function changeInfos(logo, text) {
  return {
    type: CHANGE_INFOS,
    payload: {
      logo,
      text,
    }
  }
}

export function changeTitle(title) {
  return {
    type: CHANGE_TITLE,
    payload: {
      title,
    }
  }
}

export function changePayment(currency, amount, moment) {
  return {
    type: CHANGE_PAYMENT,
    payload: {
      currency,
      amount,
      moment,
    }
  }
}

export function changeForm(form) {
  return {
    type: CHANGE_FORM,
    payload: [...form],
  }
}

export function changeGallery(gallery) {
  return {
    type: CHANGE_GALLERY,
    payload: [...gallery],
  }
}

export function changeContactEmail(value, valid) {
  return {
    type: CHANGE_CONTACT_EMAIL,
    payload: {
      value,
      valid,
    }
  }
}

export function changeFormId(formId) {
  return {
    type: CHANGE_FORM_ID,
    payload: {
      formId,
    }
  }
}

export function submitForm(data) {
  return {
    type: SUBMIT_FORM,
    payload: {
      data,
    }
  }
}

export function uploadFile(file) {
  return {
    type: UPLOAD_FILE,
    payload: {
      file,
    }
  }
}

export function resetForm() {
  return {
    type: RESET_FORM,
    payload: { }
  }
}