/**
 * Action liées à la page d'accueil
 */

import {
  GET_LEADS,
} from '../constants/actionTypes'

export function getLeads() {
  return {
    type: GET_LEADS,
    payload: {
      limit: 1,
    }
  }
}
