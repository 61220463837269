import React from 'react'
import { translate, t } from 'react-multi-lang'

import constants from '../../../constants/constants'
import LocationIcon from '../../../../assets/images/location.svg'
import MailIcon from '../../../../assets/images/mail.svg'

import LinkedinIcon from '../../../../assets/images/footer/linkedin.svg'
import TwitterIcon from '../../../../assets/images/footer/twitter.svg'
import TelegramIcon from '../../../../assets/images/footer/telegram.svg'
import MediumIcon from '../../../../assets/images/footer/medium.png'
import YoutubeIcon from '../../../../assets/images/footer/youtube.png'
import FacebookIcon from '../../../../assets/images/footer/facebook.png'

import './footer.css'

/**
 * Pied de page
 */
class Footer extends React.Component {
  render() {
    return (
      <footer id="appFooter">
        <div id="appFooterContainer">
          <section id="appFooterIntro" className="appFooterSection">
            <h2>{t('footer.intro.title')}</h2>
            <p>{t('footer.intro.text')}</p>
            <div className="appFooterNetworks">
              <ul>
                <li><a href={constants.url.linkedin} target="_blank" rel="noopener noreferrer"><img src={LinkedinIcon} alt="" /></a></li>
                <li><a href={constants.url.twitter} target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="" /></a></li>
                <li><a href={constants.url.telegram} target="_blank" rel="noopener noreferrer"><img src={TelegramIcon} alt="" /></a></li>
                <li><a href={constants.url.medium} target="_blank" rel="noopener noreferrer"><img src={MediumIcon} alt="" /></a></li>
                <li><a href={constants.url.youtube} target="_blank" rel="noopener noreferrer"><img src={YoutubeIcon} alt="" /></a></li>
                <li><a href={constants.url.facebook} target="_blank" rel="noopener noreferrer"><img src={FacebookIcon} alt="" /></a></li>
              </ul>
            </div>
          </section>
          <section id="appFooterAbout" className="appFooterSection">
            <h2>{t('footer.about.title')}</h2>
            <ul>
              <li><a href={constants.url.whoAreWe} target="_blank" rel="noopener noreferrer">{t('footer.about.whoAreWe')}</a></li>
              <li><a href={constants.url.buyBTU} target="_blank" rel="noopener noreferrer">{t('footer.about.buyBTU')}</a></li>
            </ul>
          </section>
          <section id="appFooterContact" className="appFooterSection">
            <h2>{t('footer.contact.title')}</h2>
            <ul>
              <li>
                <div className="appFooterContactIcon">
                  <img src={LocationIcon} alt="" />
                </div>
                <div className="appFooterContactText">
                  <p>
                    {t('footer.contact.address.0')}<br />
                    {t('footer.contact.address.1')}<br />
                    {t('footer.contact.address.2')}
                  </p>
                  <p>{t('footer.contact.rcs')}</p>
                </div>
              </li>
              <li>
                <div className="appFooterContactIcon">
                  <img src={MailIcon} alt="" />
                </div>
                <div className="appFooterContactText">
                  <p><a href={constants.url.mailto}>{t('footer.contact.mail')}</a></p>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </footer>
    )
  }
}

export default translate(Footer)
