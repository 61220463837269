import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

import { changeGallery } from '../../../actions/index'

import UpIcon from '../../../../assets/images/up.svg'
import DownIcon from '../../../../assets/images/down.svg'
import DeleteIcon from '../../../../assets/images/delete.svg'

import './fileManager.css'

/**
 * Gestion des images / vidéos
 */
class FileManager extends React.Component {
  state = {
    currentRow: null,
  }

  addRow = () => {
    const {
      gallery,
    } = this.props

    this.setState({
      currentRow: gallery.length,
    }, () => {
      this.refs.fileUploader.click()
    })
  }

  addVideo = () => {
    const {
      gallery,
    } = this.props

    gallery.push({
      type: 'video',
      url: '',
    })

    changeGallery(gallery)
    this.setState({ currentRow: null })
  }

  onUploadFile = (event) => {
    const {
      gallery,
      changeGallery,
    } = this.props

    const {
      currentRow,
    } = this.state

    if (currentRow && currentRow < gallery.length) {
      gallery[currentRow] = {
        type: 'image',
        file: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
      }
    } else {
      gallery.push({
        type: 'image',
        file: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
      })
    }

    changeGallery(gallery)
    this.setState({ currentRow: null })
  }

  onUp = (index) => {
    const {
      gallery,
      changeGallery,
    } = this.props

    if (index > 0 && index < gallery.length) {
      const item1 = {...gallery[index - 1]}
      const item2 = {...gallery[index]}

      gallery[index - 1] = item2
      gallery[index] = item1

      changeGallery(gallery)
    }
  }

  onDown = (index) => {
    const {
      gallery,
      changeGallery,
    } = this.props

    if (index >= 0 && index < gallery.length - 1) {
      const item1 = {...gallery[index]}
      const item2 = {...gallery[index + 1]}

      gallery[index] = item2
      gallery[index + 1] = item1

      changeGallery(gallery)
    }
  }

  onDelete = (index) => {
    const {
      gallery,
      changeGallery,
    } = this.props

    if (index >= 0 && index < gallery.length) {
      gallery.splice(index, 1)

      changeGallery(gallery)
    }
  }

  onChangeVideo = (value, index) => {
    const {
      gallery,
      changeGallery,
    } = this.props

    if (index >= 0 && index < gallery.length) {
      gallery[index].url = value

      changeGallery(gallery)
    }
  }

  /**
   * Render
   */
  render() {
    const {
      gallery,
    } = this.props

    return (
      <>
      <div className="appFormInputContainer appFormInputContainerOld">
        <label>{t('form.infos.files')}</label>
      </div>
      <div className="appFormInputContainer appFormInputContainerOld">
        {gallery.map((item, index) => (
          <div className="appFormFileItem" key={'item_' + index}>
            {item.type === 'image'
            ? (
              <>
                <div className="appFormImageContainer">
                  <img className="appFormImageItem" src={item.url} alt="" />
                </div>
              </>
            )
            : (
              <>
                <div className="appFormVideoContainer">
                  <input
                    type="text"
                    value={item.url}
                    onChange={event => this.onChangeVideo(event.target.value, index)}
                  />
                </div>
              </>
            )
            }
            <div className="appFormFileControllers">
              { index > 0
              ? (
                  <div
                    className="appFormFileControllersButton"
                    role="button"
                    onClick={event => this.onUp(index)}
                  >
                    <img src={UpIcon} alt="Monter" />
                  </div>
                )
              : (<div className="appFormFileControllersButton" />)
              }
              <div
                className="appFormFileControllersButton"
                role="button"
                onClick={event => this.onDelete(index)}
              >
                <img src={DeleteIcon} alt="Supprimer" />
              </div>
              { index < gallery.length - 1
              ? (
                  <div
                    className="appFormFileControllersButton"
                    role="button"
                    onClick={event => this.onDown(index)}
                  >
                    <img src={DownIcon} alt="Descendre" />
                  </div>
                )
              : (<div className="appFormFileControllersButton" />)
              }
            </div>
          </div>
        ))}
      </div>
      <div className="appFormInputContainer appFormInputContainerOld">
        <input
          type="file"
          accept="image/*"
          id="appFormFileUploader"
          ref="fileUploader"
          style={{display: "none"}}
          onChange={this.onUploadFile}
        />
      </div>
      <div className="appFormInputContainer appFormInputContainerOld">
        <div className="appFormAddRow">
          <div className="appFormAddButtonContainer">
            <button
              id="appFormAddImage"
              onClick={this.addRow}
            >
              {t('form.infos.addImage')}
            </button>
          </div>
          <div className="appFormAddButtonContainer">
            <button
              id="appFormAddVideo"
              onClick={this.addVideo}
            >
              {t('form.infos.addVideo')}
            </button>
          </div>
        </div>
      </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    gallery: state.formReducer.gallery,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeGallery: gallery => dispatch(changeGallery(gallery)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(FileManager))
