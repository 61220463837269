import React from 'react'
import PropTypes from 'prop-types'
import { translate, t, getLanguage, setLanguage } from 'react-multi-lang'

import Logo from '../../../../assets/images/btu_logo_blue.png'
import MenuIcon from '../../../../assets/images/menu.svg'
import EnFlag from '../../../../assets/images/flags/en.png'
import FrFlag from '../../../../assets/images/flags/fr.png'

import constants from '../../../constants/constants'

import './header.css'

/**
 * En-tête
 */
class Header extends React.Component {
  state = {
    menuDisplayed: false,
  }

  toggleMenu = () => {
    this.setState(prevState => ({ menuDisplayed: !prevState.menuDisplayed }))
  }

  changeLanguage = () => {
    if (getLanguage() === 'fr') {
      setLanguage('en')
    } else {
      setLanguage('fr')
    }
  }

  render() {
    const { menu } = this.props
    const { menuDisplayed } = this.state

    return (
      <header id="appHeader">
        <div id="appHeaderContainer">
          <div id="appHeaderLogo">
            <a href="/">
              <img id="appHeaderLogoImage" src={Logo} alt="BTU Lead" />
              <h1 id="appHeaderLogoText">{t('header.brand')}</h1>
            </a>
          </div>
          { menu
            && (
              <>
                <div
                id="appHeaderMenuIcon"
                role="button"
                onClick={this.toggleMenu}
                >
                  <img src={MenuIcon} alt="" />
                </div>
                <div
                  id="appHeaderMenuBack"
                  className={menuDisplayed ? 'appHeaderMenuVisible' : ''}
                  role="button"
                  onClick={this.toggleMenu}
                />
                <div
                  id="appHeaderMenu"
                  className={menuDisplayed ? 'appHeaderMenuVisible' : ''}
                >
                  <ul>
                    <li>
                      <a
                        className="appHeaderButton"
                        href="/create"
                      >
                        {t('header.start')}
                      </a>
                    </li>
                    <li>
                      <a
                        href={constants.url.whoAreWe}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('header.whoAreWe')}
                      </a>
                    </li>
                    <li>
                      <a
                        href={constants.url.buyBTU}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('header.buyBTU')}
                      </a>
                    </li>
                    <li>
                      <div
                        id="appHeaderLanguage"
                        role="button"
                        onClick={this.changeLanguage}
                      >
                        <img src={getLanguage() === 'fr' ? FrFlag : EnFlag} alt="" />
                      </div>
                    </li>
                  </ul>
                </div>
              </>            
            )
          }
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  menu: PropTypes.bool,
}

Header.defaultProps = {
  menu: false,
}

export default translate(Header)
