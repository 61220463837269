import React from 'react'
import { translate, t } from 'react-multi-lang'

/**
 * Réponse réussie à un formulaire utilisateur
 */
class DataSuccess extends React.Component {
  /**
   * Render
   */
  render() {
    return (
      <section className="appDataSection">
        <h2 dangerouslySetInnerHTML={{ __html: t('user.success.title')}} />
        <div className="appDataContainer">
          <div className="appDataInputContainer">
            <p>{t('user.success.text')}</p>
          </div>
        </div>
      </section>
    )
  }
}

export default translate(DataSuccess)
