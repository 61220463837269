import React from 'react'
import { translate, t } from 'react-multi-lang'

class HomeMore extends React.Component {
  render() {
    return (
      <section className="appHomeSection appHomeMore">
        <p>{t('home.more.text')}</p>
        <a href="mailto:info@btu-protocol.com?subject=[BTU Lead]">{t('home.more.button')}</a>
      </section>
    )
  }
}

export default translate(HomeMore)
