import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'
import { Editor } from '@tinymce/tinymce-react'

import { changeInfos } from '../../../actions/index'

import './descriptionEditor.css'

/**
 * Éditeur enrichi pour la description
 */
class DescriptionEditor extends React.Component {
  /**
   * Modification de la zone de texte
   *
   * @param {string} content Nouveau contenu de la zone de texte
   */
  onChangeText = (content) => {
    const {
      infos,
      changeInfos,
    } = this.props

    const logo = infos.logo
    const text = content.replace(/(?:\r\n|\r|\n)/g, '')

    changeInfos(logo, text)
  }

  /**
   * Render
   */
  render() {
    // Barre d'outils de l'éditeur de texte
    const editorToolbar = [
      ['undo', 'redo'],
      ['formatselect'],
      ['bold', 'italic', 'backcolor'],
      ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
      ['bullist', 'numlist', 'outdent', 'indent'],
      ['removeformat'],
    ]

    return (
      <>
        <div className="appFormInputContainer appFormInputContainerOld">
          <label htmlFor="appFormText">{t('form.infos.text')}</label>
        </div>
        <Editor
          id="appFormText"
          initialValue=''
          init={{
            height: 500,
            menubar: false,
            plugins: ['lists'],
            toolbar: editorToolbar.map(item => item.join(' ')).join(' | '),
            language: 'fr_FR',
          }}
          onEditorChange={this.onChangeText}
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    infos: state.formReducer.infos,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeInfos: (logo, text) => dispatch(changeInfos(logo, text)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(DescriptionEditor))
