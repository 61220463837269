import React from 'react'
import { connect } from 'react-redux'

import Header from '../../elements/header'
import Footer from '../../elements/footer'

import FormInfos from './formInfos'
import FormPayment from './formPayment'
import FormCustom from './formCustom'
import FormContact from './formContact'
import FormSuccess from './formSuccess'

import './form.css'

/**
 * Page de formulaire
 */
class Form extends React.Component {
  render() {
    const { page } = this.props

    return (
      <div id="appContainer" className="appContainerForm">
        <Header />
        <div id="appContent">
          <div id="appContentContainer">
            { page === 0
              && (
                <FormInfos />
              )
            }
            { page === 1
              && (
                <FormPayment />
              )
            }
            { page === 2
              && (
                <FormCustom />
              )
            }
            { page === 3
              && (
                <FormContact />
              )
            }
            { page === 4
              && (
                <FormSuccess />
              )
            }
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    page: state.formReducer.page,
  }
}

export default connect(
  mapStateToProps,
  null,
)(Form)
