import React from 'react'
import { translate, t } from 'react-multi-lang'

import IntroImg from '../../../../assets/images/home/intro.png'

class HomeIntro extends React.Component {
  render() {
    return (
      <section className="appHomeSection appHomeIntro">
        <div>
          <h2>{t('home.intro.title')}</h2>
          <p className="appHomeIntroSubtitle">{t('home.intro.text')}</p>
          <p className="appHomeIntroButton">
            <a href="/create">{t('home.intro.start')}</a>
            <span>{t('home.intro.free')}</span>
          </p>
        </div>
        <div>
          <img src={IntroImg} alt="" />
        </div>
      </section>
    )
  }
}

export default translate(HomeIntro)
