import React from 'react'

import {
  Header,
  Footer,
} from '../../elements'

import HomeIntro from './homeIntro'
import HomeFinder from './homeFinder'
import HomeLeads from './homeLeads'
import HomeHow from './homeHow'
import HomeMore from './homeMore'

import './home.css'

/**
 * Page d'accueil
 */
class Home extends React.PureComponent {
  render() {
    return (
      <div id="appContainer" className="homeContainer">
        <Header
          menu={true}
        />
        <div id="appContent">
          <div id="appContentContainer">
            <HomeIntro />
            <HomeFinder />
            <HomeLeads />
            <HomeHow />
          </div>
        </div>
        <HomeMore />
        <Footer />
      </div>
    )
  }
}

export default Home
