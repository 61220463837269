import React from 'react'
import { translate, t } from 'react-multi-lang'

import {
  LogoUploader,
  Title,
  DescriptionEditor,
  PageManager,
  FileManager,
} from '../../elements'

/**
 * Formulaire d'informations
 */
class FormInfos extends React.Component {
  render() {
    return (
      <section className="appFormSection">
        <h2 dangerouslySetInnerHTML={{ __html: t('form.infos.title')}} />
        <div className="appFormContainer">
          <LogoUploader />
          <Title />
          <DescriptionEditor />
          <FileManager />
        </div>
        <PageManager />
      </section>
    )
  }
}

export default translate(FormInfos)
