import { combineReducers } from 'redux'

import homeReducer from './homeReducer'
import formReducer from './formReducer'
import dataReducer from './dataReducer'

export default combineReducers(
  {
    homeReducer,
    formReducer,
    dataReducer,
  }
)
