import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

/**
 * Section paiement
 */
class DataPayment extends React.Component {
  /**
   * Render
   */
  render() {
    const {
      userForm,
    } = this.props

    return (
      <section className="appDataSection">
        <h2 dangerouslySetInnerHTML={{ __html: t('user.payment')}} />
        <div className="appDataContainer appDataContainerLeft">
          <p>
            {t('user.amount')}
            {' '}
            {userForm.data.payment.amount}
            {' '}
            {userForm.data.payment.currency === '€' ? '€ ' + t('user.inBTU') : userForm.data.payment.currency}
          </p>
          <p>
            {t('form.payment.moment.values.' + (userForm.data.payment.moment - 1))}
          </p>
        </div>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    userForm: state.dataReducer.userForm,
  }
}

export default connect(
  mapStateToProps,
  null
)(translate(DataPayment))
