import {
  GET_LEADS_READY,
} from '../constants/actionTypes'

import initialState from './initialState'

export default function formReducer(state, action) {
  // Initialisation de l'état s'il n'est pas défini
  if (!state) {
    state = JSON.parse(JSON.stringify(initialState))
  }

  switch (action.type) {
    case GET_LEADS_READY:
      let leads = []
      if (action.payload.leads && Array.isArray(action.payload.leads)) {
        leads = [...action.payload.leads]
      }

      return Object.assign({}, state, {
        leads,
      })

    default:
      return state
  }
}
