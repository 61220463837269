import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Home from '../views/home'
import Form from '../views/form'
import Data from '../views/data'

import constants from '../../constants/constants'

const Root = ({ store }) => (
  <Provider store={store}>
    <Router basename={constants.basename}>
      <Switch>
        <Route exact path='/create' component={Form} />
        <Route exact path='/:query' component={Data} />
        <Route exact path='/' component={Home} />
      </Switch>
    </Router>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root
