import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

import { changeTitle } from '../../../actions/index'

/**
 * Ligne de titre
 */
class Title extends React.Component {
  /**
   * Modification de l'input
   *
   * @param {Object} event
   */
  onChange = (event) => {
    const {
      changeTitle,
    } = this.props

    changeTitle(event.target.value)
  }

  /**
   * Render
   */
  render() {
    const { title } = this.props

    return (
        <div className="appFormInputContainer appFormInputContainerOld">
          <label htmlFor="appFormTitle">{t('form.infos.addTitle')}</label>
          <input
            id="appFormTitle"
            type="text"
            value={title}
            onChange={this.onChange}
          />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    infos: state.formReducer.infos,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeTitle: title => dispatch(changeTitle(title)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(Title))
