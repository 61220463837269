import React from 'react'
import { connect } from 'react-redux'

import {
  Dappbar,
  Header,
  Footer,
} from '../../elements'

import {
  getForm,
} from '../../../actions/index'

import DataInfos from './dataInfos'
import DataPayment from './dataPayment'
import DataContact from './dataContact'
import DataForm from './dataForm'
import DataSuccess from './dataSuccess'

import './data.css'

/**
 * Page de données
 */
class Data extends React.Component {
  componentDidMount() {
    const {
      match,
      location,
      getForm,
    } = this.props

    // On parse la query passée en url
    let { query } = match.params

    // Query pas trouvée : on vérifie si elle est passée via '/?query'
    if (!query && location && location.search) {
      // Suppression du "?" initial
      query = location.search.substr(1)
    }

    if (query) {
      getForm(query)
    }
  }

  render() {
    const {
      userForm,
      apiReturn,
    } = this.props

    return (
      <div id="appContainer" className="appContainerForm appContainerData">
        <Dappbar />
        <Header />
        <div id="appContent">
          <div id="appContentContainer">
            { userForm && userForm.id && !apiReturn
              && (
                <>
                  <DataInfos />
                  <DataPayment />
                  <DataForm />
                  <DataContact />
                </>
              )
            }
            { apiReturn
              && (
                <DataSuccess />
              )
            }
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userForm: state.dataReducer.userForm,
    apiReturn: state.dataReducer.apiReturn,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    getForm: (formId) => dispatch(getForm(formId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Data)
