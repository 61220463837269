import React from 'react'
import PropTypes from 'prop-types'

import DragIcon from '../../../../assets/images/drag.svg'
import EditIcon from '../../../../assets/images/edit.svg'

import './formField.css'

/**
 * Élément de formulaire
 */
class FormField extends React.PureComponent {
  render() {
    const {
      label,
      placeholder,
      required,
      onEdit,
    } = this.props

    return (
      <div className="appFormField">
        <div className="appFormFieldContent">
          <label
            onClick={onEdit}
          >
            “{label}”
            {required ? '*' : ''}
            <img src={EditIcon} alt="" />
          </label>
          <input
            type="text"
            value={'“' + placeholder + '”'}
            disabled
          />
        </div>
        <div className="appFormFieldDrag">
          <img src={DragIcon} alt="" />
        </div>
      </div>
    )
  }
}

FormField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onEdit: PropTypes.func,
}

FormField.defaultProps = {
  id: '',
  label: '',
  placeholder: '',
  required: false,
  onEdit: () => null,
}

export default FormField
