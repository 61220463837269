import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

import {
  changePayment,
} from '../../../actions/index'

import PageManager from '../../elements/pageManager'

/**
 * Formulaire de rémunération
 */
class FormPayment extends React.Component {
  /**
   * Modification du type de paiement
   *
   * @param {Object} event Événement déclenché ; la nouvelle valeur entrée est
   *   contenue dans <code>event.target.value</code>
   */
  onChangeCurrency = (event) => {
    const {
      payment,
      changePayment,
    } = this.props

    changePayment(event.target.value, payment.amount, payment.moment)
  }

  onChangeAmount = (event) => {
    const {
      payment,
      changePayment,
    } = this.props

    changePayment(payment.currency, event.target.value, payment.moment)
  }

  onChangeMoment = (event) => {
    const {
      payment,
      changePayment,
    } = this.props

    changePayment(payment.currency, payment.amount, event.target.value)
  }

  /**
   * Render
   */
  render() {
    const {
      payment,
    } = this.props

    const moments = [1, 2, 3]

    return (
      <section className="appFormSection">
        <h2 dangerouslySetInnerHTML={{ __html: t('form.payment.title')}} />
        <div className="appFormContainer">
          <div className="appFormInputContainer appFormInputContainerOld">
            <label>{t('form.payment.currency.title')}</label>
            <ul>
              <li className="appFormRadioContainer">
                <input
                  type="radio"
                  id="appFormCurrencyEUR"
                  value="EUR"
                  checked={payment.currency === 'EUR'}
                  onChange={this.onChangeCurrency}
                />
                <label htmlFor="appFormCurrencyEUR" className="appFormRadioLabel">{t('form.payment.currency.eur')}</label>
              </li>
              <li>
                <input
                  type="radio"
                  id="appFormCurrencyBTU"
                  value="BTU"
                  checked={payment.currency === 'BTU'}
                  onChange={this.onChangeCurrency}
                />
                <label htmlFor="appFormCurrencyBTU" className="appFormRadioLabel">{t('form.payment.currency.btu')}</label>
              </li>
            </ul>
          </div>
          <div className="appFormInputContainer appFormInputContainerOld">
            <label htmlFor="appFormAmount">{t('form.payment.amount.title')}</label>
            <input
              id="appFormAmount"
              type="number"
              value={payment ? payment.amount : 0}
              onChange={this.onChangeAmount}
            />
          </div>
          <div className="appFormInputContainer appFormInputContainerOld">
            <label>{t('form.payment.moment.title')}</label>
            <ul>
              {moments.map(item => (
                <li className="appFormRadioContainer" key={'appFormMoment' + item}>
                  <input
                    type="radio"
                    id={'appFormMoment' + item}
                    value={item}
                    checked={payment.moment === item}
                    onChange={this.onChangeMoment}
                  />
                  <label htmlFor={'appFormMoment' + item} className="appFormRadioLabel">{t('form.payment.moment.values.' + (item - 1))}</label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <PageManager />
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    payment: state.formReducer.payment,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changePayment: (currency, amount, moment) => dispatch(changePayment(currency, amount, moment)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(FormPayment))
