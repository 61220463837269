import React from 'react'
import { connect } from 'react-redux'
// import { translate, t } from 'react-multi-lang'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

/**
 * En-tête de formulaire
 */
class DataInfos extends React.PureComponent {
  /**
   * Render
   */
  render() {
    const {
      userForm,
    } = this.props

    return (
      <section className="appDataSection">
        <div className="appDataContainer">
          { userForm.data.infos.logo
            && (
              <div className="appDataLogo">
                <img src={userForm.data.infos.logo} alt="" />
              </div>
            )
          }
          <div className="appDataText">
            {userForm.data.infos.text.split('\\n').map((item, index) => (
              <p key={item + '_' + index} dangerouslySetInnerHTML={{ __html: item.replace('\\t', '<span className="appDataTextSpacer">') }} />
            ))}
          </div>
          { userForm.data.infos.gallery.length > 0
            && (
              <div className="appDataCarousel">
                <Carousel>
                  { userForm.data.infos.gallery.map(item => (
                    <div className="appDataCarouselContent" key={item.value}>
                      { item.type === 'image'
                      ? (
                        <img src={item.value} alt="" style={{ width: 'auto', maxHeight: 400 }}/>
                        )
                      : (
                        <iframe
                          title="video"
                          src={item.value}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                        )
                      }
                    </div>
                    )
                  )}
                </Carousel>
              </div>
            )
          }
        </div>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    userForm: state.dataReducer.userForm,
  }
}

export default connect(
  mapStateToProps,
  null
//)(translate(DataInfos))
)(DataInfos)
