import React from 'react'
import { getLanguage } from 'react-multi-lang'

import constants from '../../../constants/constants'

class Dappbar extends React.Component {
  state = {
    dappbarEnabled: false,
  }

  componentDidMount() {
    this.initDappMode()
    // Écoute de l'événement dappbar
    window.addEventListener('BTU-dappbarEnabled', this.dappbarEnabled, false)
  }

  componentWillUnmount() {
    // Suppression de l'écoute de l'événement dappbar
    window.removeEventListener('BTU-dappbarEnabled', this.dappbarEnabled, false)
  }

  /**
   * Initialisation éventuelle du mode dapp
   */
  initDappMode = () => {
    // Recherche dans le DOM si le script dappbar est déjà inclus
    let found = false
    const scripts = document.getElementsByTagName('script')
    for (let i = scripts.length; i--;) {
      if (scripts[i].src === constants.url.dappbar) {
        found = true
        break
      }
    }

    if (!found) {
      // On n'a pas trouvé le script : on l'ajoute : l'initialisation de la langue
      // sera effectuée lorsque l'événement 'BTU-dappbarEnabled' sera lancé par la dappbar.
      const btudapp = document.createElement('script')
      btudapp.src = constants.url.dappbar
      btudapp.async = true
      document.body.appendChild(btudapp)
    } else {
      // Initialisation de la langue
      if (window._btu_setLanguage) {
        window._btu_setLanguage(getLanguage())
      }
      // Render de la dappbar
      if (window._btu_loadDappbar) {
        window._btu_loadDappbar()
      }
    }
  }

  /**
   * Fonction appelée lorsque l'événement 'BTU-dappbarEnabled' est capturé
   */
  dappbarEnabled = () => {
    const { dappbarEnabled } = this.state
    if (!dappbarEnabled) {
      // Initialisation de la langue
      if (window._btu_setLanguage) {
        window._btu_setLanguage(getLanguage())
      }
      // Render de la dappbar
      if (window._btu_loadDappbar) {
        window._btu_loadDappbar()
      }
      this.setState({ dappbarEnabled: true })
    }
  }

  render() {
    return (
      <div id="btu-placeholder" />
    )
  }
}

Dappbar.propTypes = { }

Dappbar.defaultProps = { }

export default Dappbar
