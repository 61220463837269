/**
 * Action liées au formulaire utilisateur
 */

import {
  GET_FORM,
  CHANGE_CONTACT_VALUE,
  CHANGE_FIELD_VALUE,
  CHANGE_DISPLAY_ERRORS,
  SUBMIT_USER_FORM,
} from '../constants/actionTypes'

export function getForm(data) {
  return {
    type: GET_FORM,
    payload: {
      data,
    }
  }
}

export function changeContactValue(field, value, valid) {
  return {
    type: CHANGE_CONTACT_VALUE,
    payload: {
      field,
      value,
      valid,
    }
  }
}

export function changeFieldValue(index, value, valid) {
  return {
    type: CHANGE_FIELD_VALUE,
    payload: {
      index,
      value,
      valid,
    }
  }
}

export function changeDisplayErrors(value) {
  return {
    type: CHANGE_DISPLAY_ERRORS,
    payload: {
      value,
    }
  }
}

export function submitUserForm(form) {
  return {
    type: SUBMIT_USER_FORM,
    payload: {
      form: {...form}
    }
  }
}
