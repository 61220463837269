import React from 'react'
import PropTypes from 'prop-types'
import { translate, t } from 'react-multi-lang'
import { getLanguage } from 'react-multi-lang'

import { formatPrice } from '../../../helpers'

class HomeLeadCard extends React.Component {
  render() {
    const {
      id,
      image,
      title,
      text,
      amount,
      currency,
      moment,
    } = this.props

    return (
      <div>
        <a href={'/' + id}>
          <div className="appHomeLeadCard">
            <div className="appHomeLeadCardImage">
              <img src={image} alt="" />
            </div>
            <div className="appHomeLeadCardText">
              <h3>{title}</h3>
              <p className="appHomeLeadCardContent" dangerouslySetInnerHTML={{ __html: text.replace(/<[^>]*>/g, ' ')}} />
              <p className="appHomeLeadPrice">
                <span className="appHomeLeadMore">{t('home.leads.more')}</span><br />
                {t('home.leads.win')}
                {' '}
                <strong>{formatPrice(amount, (currency === 'BTU' ? 'BTU' : '€'), getLanguage())}</strong><br />
                {currency === 'EUR'
                  && (
                    <>
                      <span className="appHomeLeadGreen">{t('home.leads.inBTU')}</span><br />
                    </>
                  )
                }
                <span className="appHomeLeadGreen">{t('home.leads.moment.' + (moment - 1))}</span>
              </p>
              </div>
          </div>
        </a>
      </div>
    )
  }
}

HomeLeadCard.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  amount: PropTypes.number,
  moment: PropTypes.number,
}

HomeLeadCard.defaultProps = {
  id: null,
  image: null,
  title: null,
  text: null,
  price: null,
  moment: null,
}

export default translate(HomeLeadCard)
