import { takeEvery, call, put } from 'redux-saga/effects'
import { 
  API_ERROR,
  GET_LEADS,
  GET_LEADS_READY,
  UPLOAD_FILE,
  UPLOAD_FILE_READY,
  SUBMIT_FORM,
  SUBMIT_FORM_READY,
  GET_FORM,
  GET_FORM_READY,
  SUBMIT_USER_FORM,
  SUBMIT_USER_FORM_READY,
} from '../constants/actionTypes'

import constants from '../constants/constants'

/**
 * Watcher function
 */
export default function* watcherSaga() {
  yield takeEvery(GET_LEADS, getLeadsSaga)
  yield takeEvery(UPLOAD_FILE, uploadFileSaga)
  yield takeEvery(SUBMIT_FORM, submitFormSaga)
  yield takeEvery(GET_FORM, getFormSaga)
  yield takeEvery(SUBMIT_USER_FORM, submitUserFormSaga)
}

/**
 * Catch d'une demande de leads
 */
function* getLeadsSaga(args) {
  try {
    const payload = yield call(getLeads, args)
    yield put({ type: GET_LEADS_READY, payload })
  } catch (e) {
    yield put({ type: API_ERROR, payload: e })
  }
}

/**
 * Récupération des leads dans la bdd
 */
function getLeads(args) {
  // Paramêtres de requête API
  const requestParameters = {
    url: constants.btuClaim.url,
    port: constants.btuClaim.port,
    action: constants.btuClaim.actions.leads,
    payload: {},
  }

  return new Promise((resolve, reject) => {
    postJSON(requestParameters)
    .then(data => {
      if (!data.error) {
        resolve(data.response)
      } else {
        reject(data)
      }
    })
  })
}

/**
 * Catch d'un upload fichier
 */
function* uploadFileSaga(args) {
  try {
    const payload = yield call(uploadFile, args)
    yield put({ type: UPLOAD_FILE_READY, payload })
  } catch (e) {
    yield put({ type: API_ERROR, payload: e })
  }
}

/**
 * Soumission de formulaire
 */
function uploadFile(args) {
  // Paramêtres de requête API
  const requestParameters = {
    url: constants.btuClaim.url,
    port: constants.btuClaim.port,
    action: constants.btuClaim.actions.upload,
    payload: args.payload.file,
  }

  return new Promise((resolve, reject) => {
    postFile(requestParameters)
    .then(data => {
      if (!data.error) {
        resolve(data)
      } else {
        reject(data)
      }
    })
  })
}

/**
 * Catch d'un appel de soumission de formulaire
 */
function* submitFormSaga(args) {
  try {
    const payload = yield call(submitForm, args)
    yield put({ type: SUBMIT_FORM_READY, payload })
  } catch (e) {
    yield put({ type: API_ERROR, payload: e })
  }
}

/**
 * Soumission de formulaire
 */
function submitForm(args) {
  // Paramêtres de requête API
  const requestParameters = {
    url: constants.btuClaim.url,
    port: constants.btuClaim.port,
    action: constants.btuClaim.actions.create,
    payload: { ...args.payload.data },
  }

  return new Promise((resolve, reject) => {
    postJSON(requestParameters)
    .then(data => {
      if (!data.error) {
        resolve(data)
      } else {
        reject(data)
      }
    })
  })
}

/**
 * Catch d'un appel de récupération de formulaire
 */
function * getFormSaga(args) {
  try {
    const payload = yield call(getForm, args)
    yield put({ type: GET_FORM_READY, payload })
  } catch (e) {
    yield put({ type: API_ERROR, payload: e })
  }
}

/**
 * Récupération de formulaire dans la bdd
 */
function getForm(args) {
  // Paramêtres de requête API
  const requestParameters = {
    url: constants.btuClaim.url,
    port: constants.btuClaim.port,
    action: constants.btuClaim.actions.get,
    payload: {
      id: args.payload.data,
    },
  }

  return new Promise((resolve, reject) => {
    postJSON(requestParameters)
    .then(data => {
      if (!data.error) {
        resolve(data.response)
      } else {
        reject(data)
      }
    })
  })
}

/**
 * Catch d'un appel de soumission de formulaire utilisateur
 */
function* submitUserFormSaga(args) {
  try {
    const payload = yield call(submitUserForm, args)
    yield put({ type: SUBMIT_USER_FORM_READY, payload })
  } catch (e) {
    yield put({ type: API_ERROR, payload: e })
  }
}

/**
 * Soumission de formulaire
 */
function submitUserForm(args) {
  // Paramêtres de requête API
  const requestParameters = {
    url: constants.btuClaim.url,
    port: constants.btuClaim.port,
    action: constants.btuClaim.actions.insert,
    payload: { ...args.payload.form },
  }

  return new Promise((resolve, reject) => {
    postJSON(requestParameters)
    .then(data => {
      if (!data.error) {
        resolve(data)
      } else {
        reject(data)
      }
    })
  })
}

/**
 * Appel de l'API BTU avec une méthode POST
 *
 * @param {Object} requestParameters - Paramètres à passer à l'API
 */
function postJSON(requestParameters) {
  // Paramêtres de requête fetch
  const requestInit = {
    method: 'POST',
    mode: 'cors',
    cache: 'default',
    body: JSON.stringify(requestParameters.payload),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }

  if (requestParameters.url && requestParameters.action) {
    // Construction de l'URL des paramètres fournis
    let requestURL = requestParameters.url
    if (requestParameters.port) {
      requestURL += ':' + requestParameters.port
    }
    requestURL += '/' + requestParameters.action

    // Appel de l'API
    return new Promise((resolve, reject) => {
      fetch(requestURL, requestInit)
      .then(response => { resolve(response.json()) })
      .catch(error => { reject(error) })
    })
  } else {
    return new Promise((resolve, reject) => {
      reject('error')
    })
  }
}

/**
 * Appel de l'API BTU avec une méthode POST pour upload fichier
 *
 * @param {Object} requestParameters - Paramètres à passer à l'API
 */
function postFile(requestParameters) {
  const formData  = new FormData()
  formData.append('file', requestParameters.payload)

  // Paramêtres de requête fetch
  const requestInit = {
    method: 'POST',
    mode: 'cors',
    cache: 'default',
    // body: requestParameters.payload,
    body: formData,
    /*headers: {
      'Accept': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded'
      //'Content-Type': 'multipart/form-data'
      // 'Content-Type': 'application/json'
    },*/
  }

  if (requestParameters.url && requestParameters.action) {
    // Construction de l'URL des paramètres fournis
    let requestURL = requestParameters.url
    if (requestParameters.port) {
      requestURL += ':' + requestParameters.port
    }
    requestURL += '/' + requestParameters.action

    // Appel de l'API
    return new Promise((resolve, reject) => {
      fetch(requestURL, requestInit)
      .then(response => { resolve(response.json()) })
      .catch(error => { reject(error) })
    })
  } else {
    return new Promise((resolve, reject) => {
      reject('error')
    })
  }
}