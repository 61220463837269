import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

import {
  changeFieldValue,
} from '../../../actions/index'

import DataField from '../../elements/dataField'

/**
 * Section formulaire
 */
class DataForm extends React.Component {
  onChange = (index, value, valid = true) => {
    const { changeFieldValue } = this.props

    changeFieldValue(index, value, valid)
  }

  /**
   * Render
   */
  render() {
    const {
      userForm,
      displayErrors,
    } = this.props

    return (
      <section className="appDataSection">
        <h2 dangerouslySetInnerHTML={{ __html: t('user.form')}} />
        <div className="appDataContainer appDataContainerLeft">
          {userForm.data.fields.map((field, index) => (
            <DataField
              key={field.label}
              type={field.type}
              value={field.value}
              label={field.label}
              required={field.required}
              invalid={!field.valid}
              displayError={displayErrors}
              onChange={(event) => this.onChange(index, event.target.value)}
              autocomplete={'form_' + field.label}
            />
          ))}
          { userForm.requiredFields
            && (
              <div className="appDataInputContainer">
                <span className="appDataRequired">{t('user.form.required')}</span>
              </div>
            )
          }
        </div>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    displayErrors: state.dataReducer.displayErrors,
    userForm: state.dataReducer.userForm,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeFieldValue: (index, value, valid) => dispatch(changeFieldValue(index, value, valid)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate(DataForm))
