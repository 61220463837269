import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

import {
  getLeads,
} from '../../../actions/index'

import HomeLeadCard from './homeLeadCard'

class HomeLeads extends React.Component {
  componentDidMount() {
    const { getLeads } = this.props

    getLeads()
  }

  render() {
    const { leads } = this.props

    return (
      <section className="appHomeSection appHomeLeads">
        <h2>{t('home.leads.title')}</h2>
        <div className="appHomeLeadPanels">
          {leads && leads.map(lead => (
            <HomeLeadCard
              key={lead.formid}
              id={lead.formid}
              image={lead.data.infos.logo}
              title={lead.data.infos.title}
              text={lead.data.infos.text}
              amount={parseInt(lead.data.payment.amount)}
              currency={lead.data.payment.currency}
              moment={lead.data.payment.moment}
            />
          ))}
        </div>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    leads: state.homeReducer.leads,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    getLeads: () => dispatch(getLeads()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(HomeLeads))
