/**
 * État initial du reducer
 */

const initialState = {
  page: 0,
  loading: false,
  apiReturn: false,
  leads: [],
  formId: null,
  infos: {
    logo: '',
    text: '',
  },
  title: '',
  payment: {
    currency: 'EUR',
    amount: 0,
    moment: 1,
  },
  form: [
    {
      name: 'company',
      label: '',
      type: 'text',
      included: true,
      required: false,
      original: true,
    },
    {
      name: 'civility',
      label: '',
      type: 'text',
      included: true,
      required: false,
      original: true,
    },
    {
      name: 'firstName',
      label: '',
      type: 'text',
      included: true,
      required: true,
      original: true,
    },
    {
      name: 'lastName',
      label: '',
      type: 'text',
      included: true,
      required: true,
      original: true,
    },
    {
      name: 'email',
      label: '',
      type: 'email',
      included: true,
      required: true,
      original: true,
    },
    {
      name: 'phone',
      label: '',
      type: 'tel',
      included: true,
      required: true,
      original: true,
    },
    {
      name: 'address',
      label: '',
      type: 'text',
      included: false,
      required: false,
      original: true,
    },
    {
      name: 'date',
      label: '',
      type: 'date',
      included: false,
      required: false,
      original: true,
    },
    {
      name: 'time',
      label: '',
      type: 'time',
      included: false,
      required: false,
      original: true,
    },
  ],
  gallery: [],
  displayErrors: false,
  contactEmail: {
    value: '',
    valid: true,
  },
  userContact: {
    name: {
      type: 'text',
      value: '',
      required: true,
      valid: true,
    },
    email: {
      type: 'email',
      value: '',
      required: true,
      valid: true,
    },
    phone: {
      type: 'tel',
      value: '',
      required: true,
      valid: true,
    },
  },
  userForm: {
    id: null,
    email: null,
    data: {
      fields: [],
      infos: {
        logo: null,
        text: null,
      },
      payment: {
        amount: 0,
        currency: null,
        moment: 0,
      }
    }
  },
}

export default initialState
