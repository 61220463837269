import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'
import PropTypes from 'prop-types'

import {
  changePage,
} from '../../../actions/index'

import LeftIcon from '../../../../assets/images/left.svg'
import RightIcon from '../../../../assets/images/right.svg'

import './pageManager.css'

/**
 * Gestion des boutons de changement de page
 */
class PageManager extends React.Component {
  previousPage = () => {
    const {
      page,
      changePage,
    } = this.props

    if (page > 0) {
      changePage(page - 1)
    }
  }

  nextPage = () => {
    const {
      page,
      changePage,
    } = this.props

    changePage(page + 1)
  }

  /**
   * Render
   */
  render() {
    const {
      page,
      noNext,
    } = this.props

    return (
      <div id="appPageManager">
        <div>
          { page > 0
            && (
              <button
                onClick={this.previousPage}
              >
                <img src={LeftIcon} alt={t('pages.previous')} />
                <span>{t('pages.previous')}</span>
              </button>
            )
          }
        </div>
        <div>
          { !noNext
            && (
              <button
                onClick={this.nextPage}
              >
                <span>{t('pages.next')}</span>
                <img src={RightIcon} alt={t('pages.next')} />
              </button>
            )
          }
        </div>
      </div>
    )
  }
}

PageManager.propTypes = {
  noNext: PropTypes.bool,
}

PageManager.defaultProps = {
  noNext: false,
}

function mapStateToProps(state) {
  return {
    page: state.formReducer.page,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changePage: (page) => dispatch(changePage(page)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(PageManager))
