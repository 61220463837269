import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

import { changeInfos } from '../../../actions/index'

/**
 * Bouton de chargement d'un logo
 */
class LogoUploader extends React.Component {
  state = {
    logoURL: null, // URL du logo, modifiée lors du chargement
  }

  /**
   * Modification du logo
   *
   * @param {Object} event Événement déclenché ; la nouvelle valeur entrée est
   *   contenue dans <code>event.target.files</code>
   */
  onUploadLogo = (event) => {
    const {
      infos,
      changeInfos,
    } = this.props

    const logo = event.target.files[0]
    const text = infos.text

    // Met à jour la section 'infos' du state général
    changeInfos(logo, text)

    // Création d'une URL pour afficher le fichier dans le champ <img> correspondant
    this.setState({
      logoURL: URL.createObjectURL(event.target.files[0])
    })
  }

  /**
   * Render
   */
  render() {
    const {
      logoURL,
    } = this.state

    return (
      <div className="appFormInputContainer appFormInputContainerOld">
        { logoURL
          && (
            <img id="appFormLogoImage" src={logoURL} alt="" />
          )
        }
        <button
          id="appFormLogoButton"
          onClick={event => this.refs.logoUploader.click()}
        >
          {logoURL ? t('form.infos.logoChange') : t('form.infos.logoUpload')}
        </button>
        <input
          type="file"
          accept="image/*"
          id="appFormLogoFile"
          ref="logoUploader"
          style={{display: "none"}}
          onChange={this.onUploadLogo}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    infos: state.formReducer.infos,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeInfos: (logo, text) => dispatch(changeInfos(logo, text)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(LogoUploader))
