import React from 'react'
import { translate, t } from 'react-multi-lang'

import LetterImg from '../../../../assets/images/home/letter.png'
import EnveloppeImg from '../../../../assets/images/home/enveloppe.png'
import MoneyImg from '../../../../assets/images/home/money.png'

class HomeHow extends React.Component {
  render() {
    const panels = [
      LetterImg,
      EnveloppeImg,
      MoneyImg
    ]

    return (
      <section className="appHomeSection appHomeHow">
        <h2 dangerouslySetInnerHTML={{ __html: t('home.how.title')}} />
        <div className="appHomeThreePanels">
          {panels.map((image, index) => (
            <div key={image}>
              <img src={image} alt="" />
              <h3>{t('home.how.panels.' + index + '.title')}</h3>
              <p>{t('home.how.panels.' + index + '.text')}</p>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

export default translate(HomeHow)
