import React from 'react'
import PropTypes from 'prop-types'
import { translate, t } from 'react-multi-lang'

import './dataField.css'

/**
 * Pied de page
 */
class DataField extends React.Component {
  render() {
    const {
      type,
      label,
      value,
      required,
      onChange,
      invalid,
      displayError,
      oldStyle,
      autocomplete,
    } = this.props

    return (
      <div className={'appFormInputContainer' + (oldStyle ? ' appFormInputContainerOld': '')}>
        <label
          className="appFormInputLabel"
          htmlFor={'appFormUser' + label}
        >
          {label}
          {required ? '*' : ' (' + t('user.optional') + ')'}
        </label>
        <input
          id={'appFormUser' + label}
          className="appFormInput"
          type={type}
          value={value}
          required={required}
          onChange={onChange}
          autocomplete={autocomplete}
        />
        { displayError && required && (value === null || value === '')
          && (
            <span className="appFormError">{t('user.error.empty')}</span>
          )
        }
        { displayError && invalid
          && (
            <span className="appFormError">{t('user.error.invalid')}</span>
          )
        }
      </div>
    )
  }
}

DataField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  displayErrors: PropTypes.bool,
  onChange: PropTypes.func,
  oldStyle: PropTypes.bool,
  autocomplete: PropTypes.string,
}

DataField.defaultProps = {
  type: 'text',
  label: '',
  value: '',
  required: false,
  invalid: false,
  displayErrors: false,
  onChange: () => null,
  oldStyle: false,
  autocomplete: null,
}

export default translate(DataField)
