/**
 * constants.js
 *
 * Définition des constantes générales
 */

const constants = {
  basename: '/',
  default_language: 'fr',
  languages: ['fr', 'en'],
  url: {
    whoAreWe: 'https://btu-protocol.com/about',
    buyBTU: 'https://btu-protocol.com/buybtu',
    mailto: 'mailto:info@btu-protocol.com',
    btuLead: 'https://btu-lead.com',
    dappbar: 'https://btu-cdn.s3.eu-west-3.amazonaws.com/public/dappbar.min.js',
    images: '',
    linkedin: 'https://www.linkedin.com/company/btu-protocol/',
    twitter: 'https://twitter.com/BtuProtocol',
    medium: 'https://medium.com/btuprotocolteam',
    instagram: 'https://www.instagram.com/btuprotocol/',
    facebook: 'https://www.facebook.com/btuprotocol/',
    telegram: 'https://t.me/btucommunity',
    youtube: 'https://www.youtube.com/channel/UC4TU0cH82u0kLeEomf26Z0g/',
  },
  btuClaim: {
    url: 'https://claim-api.btu-direct.com/',
    port: null,
    actions: {
      create: 'lead/create',
      get: 'lead/get',
      insert: 'lead/insert',
      upload: 'lead/upload',
      leads: 'lead/leads',
    },
  },
}

// Adresse BTU Claim différente suivant LOCAL / STAGE / PROD
if (process.env.REACT_APP_ENV === 'LOCAL') {
  constants.btuClaim.url = 'http://localhost'
  constants.btuClaim.port = 8081
  constants.url.btuLead = 'http://localhost:3000'
} else
if (process.env.REACT_APP_ENV === 'STAGE') {
  constants.btuClaim.url = 'http://ec2-35-180-111-214.eu-west-3.compute.amazonaws.com'
  constants.btuClaim.port = 8081
  constants.url.btuLead = 'http://btu-lead-stage.s3-website.eu-west-3.amazonaws.com'
}

// Génération de l'url du répertoire contenant les images distantes à partir de l'adresse BTU Claim
constants.url.images = constants.btuClaim.url
if (constants.btuClaim.port) {
  constants.url.images += ':' + constants.btuClaim.port + '/'
}
constants.url.images += 'static/'

export default constants
