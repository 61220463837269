export const GET_LEADS = 'GET_LEADS'
export const GET_LEADS_READY = 'GET_LEADS_READY'
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const CHANGE_LOGO = 'CHANGE_LOGO'
export const CHANGE_INFOS = 'CHANGE_INFOS'
export const CHANGE_TITLE = 'CHANGE_TITLE'
export const CHANGE_PAYMENT = 'CHANGE_PAYMENT'
export const CHANGE_FORM = 'CHANGE_FORM'
export const CHANGE_GALLERY = 'CHANGE_GALLERY'
export const CHANGE_CONTACT_EMAIL = 'CHANGE_CONTACT_EMAIL'
export const CHANGE_FORM_ID = 'CHANGE_FORM_ID'
export const SUBMIT_FORM = 'SUBMIT_FORM'
export const SUBMIT_FORM_READY = 'SUBMIT_FORM_READY'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_READY = 'UPLOAD_FILE_READY'
export const API_ERROR = 'API_ERROR'
export const RESET_FORM = 'RESET_FORM'
export const GET_FORM = 'GET_FORM'
export const GET_FORM_READY = 'GET_FORM_READY'
export const CHANGE_CONTACT_VALUE = 'CHANGE_CONTACT_VALUE'
export const CHANGE_FIELD_VALUE = 'CHANGE_FIELD_VALUE'
export const CHANGE_DISPLAY_ERRORS = 'CHANGE_DISPLAY_ERRORS'
export const SUBMIT_USER_FORM = 'SUBMIT_USER_FORM'
export const SUBMIT_USER_FORM_READY = 'SUBMIT_USER_FORM_READY'
