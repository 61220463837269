/**
 * Gestion des langues
 */
import { setDefaultTranslations, setDefaultLanguage, setLanguage } from 'react-multi-lang'

import constants from './constants'

const defaultTranslations = {}

// On récupère les fichiers json dans le répertoire assets/translations
// en fonction des langues définies dans le fichier des constantes.
const languagesContext = require.context('../../assets/translations', true)
for (let i = 0; i < constants.languages.length; i++) {
  const languageFile = languagesContext('./' + constants.languages[i] + '.json')
  defaultTranslations[constants.languages[i]] = languageFile
}

setDefaultTranslations(defaultTranslations)
setDefaultLanguage(constants.default_language)

// Récupération de la langue précédemment stockée en session, si elle existe
if (sessionStorage.getItem('current_language') == null || typeof sessionStorage.getItem('current_language') === 'undefined')
  sessionStorage.setItem('current_language', constants.default_language);
else
  setLanguage(sessionStorage.getItem('current_language'))
