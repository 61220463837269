import {
  GET_FORM_READY,
  CHANGE_CONTACT_VALUE,
  CHANGE_FIELD_VALUE,
  CHANGE_DISPLAY_ERRORS,
  SUBMIT_USER_FORM,
  SUBMIT_USER_FORM_READY,
} from '../constants/actionTypes'

import initialState from './initialState'

export default function formReducer(state, action) {
  // Initialisation de l'état s'il n'est pas défini
  if (!state) {
    state = JSON.parse(JSON.stringify(initialState))
  }

  switch (action.type) {
    case GET_FORM_READY:
      const userForm = {...action.payload}

      // Vérification qu'il existe au moins un champ obligatoire,
      // afin d'indiquer en bas de formulaire la signification
      // de l'astérisque
      userForm.requiredFields = false
      for (let i = 0; i < action.payload.data.fields.length; i++) {
        if (action.payload.data.fields[i].required) {
          userForm.requiredFields = true
        }
      }

      return Object.assign({}, state, {
        userForm,
      })

    case CHANGE_CONTACT_VALUE:
      const newContact = {...state.userContact}
      newContact[action.payload.field].value = action.payload.value
      newContact[action.payload.field].valid = action.payload.valid
      return Object.assign({}, state, {
        userContact: {...newContact}
      })

    case CHANGE_FIELD_VALUE:
      const newFieldForm = {...state.userForm}
      newFieldForm.data.fields[action.payload.index].value = action.payload.value
      newFieldForm.data.fields[action.payload.index].valid = action.payload.valid
      return Object.assign({}, state, {
        userForm: {...newFieldForm}
      })

    case CHANGE_DISPLAY_ERRORS:
      return Object.assign({}, state, {
        displayErrors: action.payload.value
      })

    case  SUBMIT_USER_FORM:
      return Object.assign({}, state, {
        loading: true,
        apiReturn: false,
      })

    case SUBMIT_USER_FORM_READY:
      return Object.assign({}, state, {
        loading: false,
        apiReturn: true,
      })

    default:
      return state
  }
}
