import React from 'react'
import { connect } from 'react-redux'
import { translate, t } from 'react-multi-lang'

import {
  resetForm,
} from '../../../actions/index'

import constants from '../../../constants/constants'

/**
 * Création réussie d'un formulaire
 */
class FormSuccess extends React.Component {
  /**
   * Render
   */
  render() {
    const {
      formId,
      resetForm,
    } = this.props

    const formUrl = constants.url.btuLead + '/' + formId

    return (
      <section className="appFormSection">
        <h2 dangerouslySetInnerHTML={{ __html: t('form.success.title')}} />
        <div className="appFormContainer">
          <div className="appFormInputContainer">
            <p>{t('form.success.text')}</p>
            <p>
              {t('form.success.address')}
              {' '}
              <a href={formUrl} target="_blank" rel="noopener noreferrer">{formUrl}</a>
            </p>
          </div>
          <div className="appFormInputContainer">
            <button onClick={resetForm}>{t('form.success.button')}</button>
          </div>
        </div>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    formId: state.formReducer.formId,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    resetForm: () => dispatch(resetForm()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(FormSuccess))
